
import { defineComponent } from 'vue';
import KTNotificationsMenu from '@/layout/header/partials/NotificationsMenu.vue';
import KTUserMenu from '@/layout/header/partials/UserMenu.vue';
import KTSearch from '@/layout/header/partials/Search.vue';

export default defineComponent({
  name: 'topbar',
  components: {
    KTNotificationsMenu,
    KTUserMenu,
    KTSearch,
  },
});
