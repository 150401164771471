
import { defineComponent } from 'vue';
import contracts from '@/core/data/contacts';

export default defineComponent({
  name: 'drawer-messenger',
  setup() {
    return {
      contracts,
    };
  },
});
