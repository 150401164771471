
import { defineComponent, computed, onMounted, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import KTAside from '@/layout/aside/Aside.vue';
import KTHeader from '@/layout/header/Header.vue';
// import KTFooter from '@/layout/footer/Footer.vue';
import HtmlClass from '@/core/services/LayoutService';
import KTToolbar from '@/layout/toolbar/Toolbar.vue';
import KTScrollTop from '@/layout/extras/ScrollTop.vue';
import KTUserMenu from '@/layout/header/partials/ActivityDrawer.vue';
import KTLoader from '@/components/Loader.vue';
// import KTCreateApp from '@/components/modals/wizards/CreateAppModal.vue';
// import KTExplore from '@/layout/extras/Explore.vue';
import KTDrawerMessenger from '@/layout/extras/DrawerMessenger.vue';
import { Actions } from '@/store/enums/StoreEnums';
import { MenuComponent } from '@/assets/ts/components/index';
import { removeModalBackdrop } from '@/core/helpers/dom';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from '@/core/helpers/config';
import moment from 'moment';

export default defineComponent({
  name: 'Layout',
  components: {
    KTAside,
    KTHeader,
    // KTFooter,
    KTToolbar,
    KTScrollTop,
    // KTCreateApp,
    KTUserMenu,
    // KTExplore,
    KTDrawerMessenger,
    KTLoader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    const toolbarButton = computed(() => {
      return store.getters.toolbarButton;
    });

    onMounted(() => {
      moment.locale('th');

      // check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: 'sign-in' });
      }

      if (localStorage.getItem('darkMode')) {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'dark-mode');
      }

      nextTick(() => {
        reinitializeComponents();
      });

      const exceptionRouteNames = [
        'Add New Car',
        'Edit Car',
        'Customer Account Details',
        'Node Lists Wildcard',
        'Order WildCard',
        'Partner Account Details',
        'Partner Car Details',
      ];

      const routeName: any = route.name;

      // Simulate the delay page loading
      if (!exceptionRouteNames.includes(routeName)) {
        setTimeout(() => {
          // Remove page loader after some time
          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
        }, 500);
      }
    });

    watch(
      () => [route.path, store.getters.isUserAuthenticated],
      () => {
        MenuComponent.hideDropdowns(undefined);

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({ name: 'sign-in' });
        }

        nextTick(() => {
          reinitializeComponents();
        });
        removeModalBackdrop();
      }
    );

    watch(
      () => [store.getters.getErrors],
      () => {
        const status = store.getters.getErrors;

        if (status === 500) {
          router.push({ name: '500' });
        }

        if (status === 404) {
          router.push({ name: '404' });
        }
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      toolbarButton,
      themeLightLogo,
      themeDarkLogo,
    };
  },
});
