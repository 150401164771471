
import { defineComponent } from 'vue';
import { toolbarWidthFluid } from '@/core/helpers/config';

export default defineComponent({
  name: 'KToolbar',
  props: {
    breadcrumbs: Array,
    title: String,
    toolbarButton: Object,
  },
  setup() {
    // const router = useRouter();
    // const routes: any = router.options.routes[0].children;
    // const possibleRoutes = routes.filter((route) => !route.path.includes(':'));
    // const search = ref('');

    // possibleRoutes.sort((a, b) => a.name.localeCompare(b.name));

    return {
      toolbarWidthFluid,
    };
  },
});
